import {
    loginRequest,
    userInformationRequest,
    logoutUser,
    forgotPasswordRequest,
    csrfRequest,
    updateMarketingAllowedRequest,
    changePasswordRequest,
    fetchUserDocuments,
    uploadDocumentById
} from "../../workers/user";
import { useToken } from '../../components/utils/useToken';
import { userBalance, defaultBankState } from './bank';
import { onLoading } from "./bootstrap";
import { useLoadingPercent } from "../../components/utils/useLoadingPercent";
import { getContentForBonus } from "./bonus";
import { consoleError } from '../../components/utils/useHandleError';

const USER_INFORMATION = 'USER_INFORMATION';
const SUBMIT_FORM_SUCCESS = 'SUCCESS_LOGIN_FORM';
const SUBMIT_FORM_FAIL = 'FAIL_LOGIN_FORM';
const SUBMIT_FORM_SUBMITTING = 'SUBMIT_FORM_SUBMITTING';
const HANDLE_ERROR = 'HANDLE_LOGIN_ERROR';
const SUBMIT_FORGOT_PASSWORD_FORM_SUCCESS = 'SUCCESS_FORGOT_PASSWORD_FORM';
const SUBMIT_FORGOT_PASSWORD_FORM_FAIL = 'FAIL_FORGOT_PASSWORD_FORM';
const SUBMIT_FORGOT_PASSWORD_FORM_SUBMITTING = 'SUBMIT_FORGOT_PASSWORD_FORM_SUBMITTING';
const HANDLE_FORGOT_PASSWORD_FORM_ERROR = 'HANDLE_FORGOT_PASSWORD_ERROR';
const FINISH_SESSION = 'FINISH_SESSION';
const STOP_SESSION_MODAL = 'STOP_SESSION_MODAL';
const UPDATE_MARKETING_ALLOWED = 'UPDATE_MARKETING_ALLOWED';
const UPDATE_MARKETING_ALLOWED_SUCCESS = 'UPDATE_MARKETING_ALLOWED_SUCCESS';
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
const FETCH_USER_DOCS = 'FETCH_USER_DOCS';
const FETCH_USER_DOCS_SUCCESS = 'FETCH_USER_DOCS_SUCCESS';
const FETCH_USER_DOCS_FAIL = 'FETCH_USER_DOCS_FAIL';
const UPLOAD_DOC = 'UPLOAD_DOC';
const UPLOAD_DOC_SUCCESS = 'UPLOAD_DOC_SUCCESS';
const UPLOAD_DOC_FAIL = 'UPLOAD_DOC_FAIL';

export const initialState = {
    submit: {
        isSubmit: false,
        isSuccess: false,
        isFail: false
    },
    submitForgotPasswordForm: {
        isSubmit: false,
        isSuccess: false,
        isFail: false
    },
    submitChangePasswordForm: {
        isSubmit: false,
        isSuccess: false,
        isFail: false,
        errors: [],
        success: '',
    },
    submitUpdateMarketingAllowed: {
        isSubmit: false,
        isSuccess: false,
        isFail: false
    },
    response: {
        errors: [],
        success: ''
    },
    isModalStopSessionOpen: false,
    isUserInformationLoaded: false,
    information: {},
    userPrivateRoutes: ['/[lang]/profile/[profileTab]', '/[lang]/bonus'],
    isAuth: false,
    profileTabs: ['info', 'wallet', 'documents', 'password', 'responsible-gaming'],
    documentsLoading: false,
    documentsError: null,
    documents: [],
    uploadStatus: {
        id: null,
        isUploading: false,
        isSuccess: false,
        error: '',
    },
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SUBMIT_FORM_SUBMITTING:
            return {
                ...state,
                submit: {
                    isSubmit: true,
                    isSuccess: false,
                    isFail: false
                }
            };
        case SUBMIT_FORM_SUCCESS:
            useToken('unlogged', '', 'delete');
            useToken('token', action.payload.authorization.token);
            return {
                ...state,
                submit: {
                    isSubmit: false,
                    isSuccess: true,
                    isFail: false
                },
                isAuth: true
            };
        case SUBMIT_FORM_FAIL:
            return {
                ...state,
                submit: {
                    isSubmit: false,
                    isSuccess: false,
                    isFail: true
                },
                response: {
                    errors: action.payload,
                    success: ''
                }
            };
        case HANDLE_ERROR:
            return {
                ...state,
                submit: {
                    isSubmit: false,
                    isSuccess: false,
                    isFail: false
                },
                submitForgotPasswordForm: {
                    isSubmit: false,
                    isSuccess: false,
                    isFail: false
                },
                submitChangePasswordForm: {
                    isSubmit: false,
                    isSuccess: false,
                    isFail: false,
                    errors: [],
                    success: '',
                },
                submitUpdateMarketingAllowed: {
                    isSubmit: false,
                    isSuccess: false,
                    isFail: false
                },
                response: {
                    errors: [],
                    success: ''
                }
            };
        case SUBMIT_FORGOT_PASSWORD_FORM_SUBMITTING:
            return {
                ...state,
                submitForgotPasswordForm: {
                    isSubmit: true,
                    isSuccess: false,
                    isFail: false
                }
            };
        case SUBMIT_FORGOT_PASSWORD_FORM_SUCCESS:
            return {
                ...state,
                submitForgotPasswordForm: {
                    isSubmit: false,
                    isSuccess: true,
                    isFail: false
                }
            };
        case SUBMIT_FORGOT_PASSWORD_FORM_FAIL:
            return {
                ...state,
                submitForgotPasswordForm: {
                    isSubmit: false,
                    isSuccess: false,
                    isFail: true
                },
                response: {
                    errors: action.payload,
                    success: ''
                }
            };
        case HANDLE_FORGOT_PASSWORD_FORM_ERROR:
            return {
                ...state,
                submitForgotPasswordForm: {
                    isSubmit: false,
                    isSuccess: false,
                    isFail: false
                }
            };
        case FINISH_SESSION:
            useToken('unlogged', true);
            useToken('token', '', 'delete');
            useToken('refreshToken', '', 'delete');
            useToken('expires_in', '', 'delete');
            return {
                ...state,
                isAuth: false,
                information: {},
                isUserInformationLoaded: true
            };
        case STOP_SESSION_MODAL:
            return {
                ...state,
                isModalStopSessionOpen: action.payload
            };
        case USER_INFORMATION:
            return {
                ...state,
                information: action.payload.data.data,
                isAuth: true,
                isUserInformationLoaded: true
            };
        case UPDATE_MARKETING_ALLOWED:
            return {
                ...state,
                submitUpdateMarketingAllowed: {
                    isSubmit: true,
                    isSuccess: false,
                    isFail: false
                }
            };
        case UPDATE_MARKETING_ALLOWED_SUCCESS:
            useToken('token', action.payload.authorization.token);
            useToken('refreshToken', action.payload.authorization.refresh_token);
            useToken('expires_in', action.payload.authorization.expires_in);
            return {
                ...state,
                information: action.payload.data,
                submitUpdateMarketingAllowed: {
                    isSubmit: false,
                    isSuccess: true,
                    isFail: false
                }
            };
        case CHANGE_PASSWORD:
            return {
                ...state,
                submitChangePasswordForm: {
                    isSubmit: true,
                    isSuccess: false,
                    isFail: false,
                    errors: [],
                    success: '',
                }
            };
        case CHANGE_PASSWORD_SUCCESS:
            useToken('token', action.payload.authorization.token);
            useToken('refreshToken', action.payload.authorization.refresh_token);
            useToken('expires_in', action.payload.authorization.expires_in);
            return {
                ...state,
                submitChangePasswordForm: {
                    isSubmit: false,
                    isSuccess: true,
                    isFail: false,
                    errors: [],
                    success: action.payload.success
                },
            };
        case CHANGE_PASSWORD_FAIL:
            useToken('token', action.payload.authorization.token);
            useToken('refreshToken', action.payload.authorization.refresh_token);
            useToken('expires_in', action.payload.authorization.expires_in);
            return {
                ...state,
                submitChangePasswordForm: {
                    isSubmit: false,
                    isSuccess: false,
                    isFail: true,
                    errors: action.payload.errors,
                    success: '',
                },
            };
        case FETCH_USER_DOCS:
            return {
                ...state,
                documentsLoading: true,
            };
        case FETCH_USER_DOCS_SUCCESS:
            useToken('token', action.payload.authorization.token);
            useToken('refreshToken', action.payload.authorization.refresh_token);
            useToken('expires_in', action.payload.authorization.expires_in);
            return {
                ...state,
                documents: action.payload.data.document,
                documentsLoading: false,
            };
        case FETCH_USER_DOCS_FAIL:
            return {
                ...state,
                documentsError: action.payload,
                documentsLoading: false,
            };
        case UPLOAD_DOC:
            return {
                ...state,
                uploadStatus: {
                    id: action.payload,
                    isUploading: true,
                    isSuccess: false,
                    error: '',
                },
            };
        case UPLOAD_DOC_SUCCESS:
            useToken('token', action.payload.authorization.token);
            useToken('refreshToken', action.payload.authorization.refresh_token);
            useToken('expires_in', action.payload.authorization.expires_in);
            return {
                ...state,
                uploadStatus: {
                    id: action.id,
                    isUploading: false,
                    isSuccess: true,
                    error: '',
                }
            };
        case UPLOAD_DOC_FAIL:
            return {
                ...state,
                uploadStatus: {
                    id: action.id,
                    isUploading: false,
                    isSuccess: false,
                    error: action.error,
                }
            };
        default:
            return state
    }
}

export const getUserInformation = (payload, isUserPage) => {
    const success = (data) => ({
        type: USER_INFORMATION,
        payload: data
    });
    const fail = () => ({
        type: FINISH_SESSION
    });
    if (payload !== undefined) {
        return dispatch => {
            userInformationRequest()
                .then(res => {
                    dispatch(success(res));
                    dispatch(onLoading(useLoadingPercent(res.config.headers['Authorization'], 'userInformation', isUserPage)));
                    dispatch(getContentForBonus());
                })
                .catch(() => {
                    //finish init loading in case if the token is invalid or other errors on user info request
                    dispatch(onLoading(useLoadingPercent(payload, 'userInformation', isUserPage)));
                    dispatch(fail());
                });
        }
    }
    return fail('')
};

export const setModalStopSessionOpen = (payload) => ({
    type: STOP_SESSION_MODAL,
    payload
});

export const handleError = () => ({
    type: HANDLE_ERROR
});

export const handleErrorForgotPassword = () => ({
    type: HANDLE_FORGOT_PASSWORD_FORM_ERROR
});

export const finishSession = (payload) => ({
    type: FINISH_SESSION,
    payload
});

export const login = (payload) => {
    return dispatch => {
        dispatch(getUserInformation(payload));
        dispatch(userBalance(payload));
    }
};

export const submitLoginForm = (payload, param = '', successCallback) => {
    const success = (data) => ({
        type: SUBMIT_FORM_SUCCESS,
        payload: data
    });
    const submit = () => ({
        type: SUBMIT_FORM_SUBMITTING
    });
    const fail = (error) => ({
        type: SUBMIT_FORM_FAIL,
        payload: error
    });
    return dispatch => {
        dispatch(submit());
        csrfRequest()
            .then(token => loginRequest({...payload, csrf: token.data.data.token }, param)
                .then(res => {
                    successCallback();
                    dispatch(success(res.data));
                    dispatch(login(res.data.authorization.token));
                })
                .catch(err => {
                    return dispatch(fail(err));
                })
            )
            .catch(err => dispatch(fail(err)));
    }
};

export const userLogout = (successCallback) => {
    const success = (data) => ({
        type: FINISH_SESSION,
        payload: data
    });
    return dispatch => {
        logoutUser()
            .then(res => {
                successCallback();
                dispatch(success(res));
                dispatch(defaultBankState());
            })
            .catch((err) => consoleError(err));
    }
};

export const submitForgotPasswordForm = (payload) => {
    const success = (data) => ({
        type: SUBMIT_FORGOT_PASSWORD_FORM_SUCCESS,
        payload: data
    });
    const submit = () => ({
        type: SUBMIT_FORGOT_PASSWORD_FORM_SUBMITTING
    });
    const fail = (error) => ({
        type: SUBMIT_FORGOT_PASSWORD_FORM_FAIL,
        payload: error
    });
    return dispatch => {
        dispatch(submit());
        forgotPasswordRequest(payload)
            .then(res => dispatch(success(res)))
            .catch(err => {
                return dispatch(fail(err));
            })
    }
};

export const updateMarketingAllowed = (data) => {
    const loading = () => ({
        type: UPDATE_MARKETING_ALLOWED
    });
    const success = (payload) => ({
        type: UPDATE_MARKETING_ALLOWED_SUCCESS,
        payload
    });
    return dispatch => {
        dispatch(loading());
        updateMarketingAllowedRequest(data)
            .then(res => dispatch(success(res.data)))
            .catch((err) => consoleError(err));
    }
};

export const changePassword = (form) => {
    const loading = () => ({
        type: CHANGE_PASSWORD
    });
    const success = (payload) => ({
        type: CHANGE_PASSWORD_SUCCESS,
        payload
    });
    const fail = (payload) => ({
        type: CHANGE_PASSWORD_FAIL,
        payload
    });
    return dispatch => {
        dispatch(loading());
        changePasswordRequest(form)
            .then(res => dispatch(success(res.data)))
            .catch(err => dispatch(fail(err)))
    }
};

export const getUserDocuments = () => {
    const loading = () => ({
        type: FETCH_USER_DOCS
    });
    const success = (payload) => ({
        type: FETCH_USER_DOCS_SUCCESS,
        payload
    });
    const fail = (payload) => ({
        type: FETCH_USER_DOCS_FAIL,
        payload
    });
    return dispatch => {
        dispatch(loading());
        fetchUserDocuments()
            .then(res => dispatch(success(res.data)))
            .catch(err => dispatch(fail(err)))
    }
};

export const uploadDocumentFailed = (id, error) => ({
    type: UPLOAD_DOC_FAIL,
    id,
    error
});

export const uploadDocument = (id, formData) => {
    const loading = (payload) => ({
        type: UPLOAD_DOC,
        payload
    });
    const success = (payload, id) => ({
        type: UPLOAD_DOC_SUCCESS,
        payload,
        id
    });

    const data = new FormData();
    data.append('file', formData);

    return dispatch => {
        dispatch(loading(id));
        uploadDocumentById(id, data)
            .then((res) => {
                dispatch(success(res.data, id));
                dispatch(getUserDocuments());
            })
            .catch((err) => {
                const error = Array.isArray(err?.errors) ? err.errors[0]?.detail : '';
                dispatch(uploadDocumentFailed(id, error))
            })
    }
};
